
import ControllerProduct from 'Controllers/ControllerProduct'
import ProductPriceOnDemand from '@/components/modal/ProductPriceOnDemand'

export default {
  components: {
    ControllerProduct,
    ProductPriceOnDemand,
  },
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
    showDesc: {
      type: Boolean,
      default: false,
    },
    isInCategoryList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      priceOnDemandShow: false,
    }
  },
  methods: {
    checkIfProductIsInCijenaNaUpitCategory(categories = []) {
      if (!categories || !categories.length) return false
      return categories
        .map((el) => el.id)
        .some((el) => {
          return [1028, 1620].includes(el)
        })
    },
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
    checkProductTags(array, tag = null) {
      if (array && array.length) {
        return array.filter((el) =>
          ['novo', 'najprodavanie', 'popust'].includes(el.name.toLowerCase())
        )
      }
    },
  },
}
