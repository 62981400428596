import { render, staticRenderFns } from "./product.vue?vue&type=template&id=03e9047d&scoped=true"
import script from "./product.vue?vue&type=script&lang=js"
export * from "./product.vue?vue&type=script&lang=js"
import style0 from "./product.vue?vue&type=style&index=0&id=03e9047d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e9047d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerProduct: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js').default})
