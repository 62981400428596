
export default {
  props: {
    value: {
      type: [Array, Object, String],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    optionTarget: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    optionName: {
      type: String,
      default: '',
    },
    default: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      open: false,
      selection: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    initialValue() {
      return (
        this.options &&
        this.options.length &&
        this.options.filter((e) => {
          return e[`${this.optionTarget}`] === this.value
        })
      )
    },
  },
  watch: {
    model: {
      handler(v) {
        this.selection = v
      },
    },
  },
  methods: {
    generateQaName(input) {
      return input.replace(' ', '-').trim().toLowerCase()
    },
  },
}
