
import ControllerError from 'Controllers/ControllerError'
export default {
  components: {
    ControllerError,
  },
  data() {
    return {
      lodaded: false,
    }
  },
  beforeMount() {
    if (
      this.$route.path === '/checkout/failure' ||
      this.$route.path.includes('checkout/failure')
    ) {
      this.$router.push({ path: `/kosarica` })
    }
  },
  mounted() {
    setTimeout(() => {
      if (
        this.$route.path !== '/checkout/failure' ||
        !this.$route.path.includes('checkout/failure')
      ) {
        this.lodaded = true
      }
    }, 1000)
  },
}
