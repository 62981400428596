import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=79d0ceab&scoped=true"
import script from "./LoginModal.vue?vue&type=script&lang=js"
export * from "./LoginModal.vue?vue&type=script&lang=js"
import style0 from "./LoginModal.vue?vue&type=style&index=0&id=79d0ceab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d0ceab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerLogin: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js').default})
