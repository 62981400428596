
import ControllerForgotPassword from 'Controllers/user/ControllerForgotPassword'
import LoginModal from '@/components/modal/LoginModal'
export default {
  components: {
    ControllerForgotPassword,
    // eslint-disable-next-line vue/no-unused-components
    LoginModal,
  },
  data() {
    return {
      changeTextPass: false,
      changeTextPassCheck: false,
    }
  },
  methods: {
    openModalLogin() {
      this.$modal.show(
        LoginModal,
        {},
        {
          classes:
            'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important forgot-password-modal modal-custom',
          adaptive: true,
        }
      )
      this.$emit('close')
    },
    textPassword() {
      this.changeTextPass = !this.changeTextPass
    },
    textPasswordCheck() {
      this.changeTextPassCheck = !this.changeTextPassCheck
    },
    callback() {
      this.$emit('close')
    },
  },
}
