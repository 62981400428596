
import ControllerProduct from 'Controllers/ControllerProduct'

export default {
  components: {
    ControllerProduct,
  },
  props: {
    hideButton: {
      type: Boolean,
      default: false,
    },
    showDesc: {
      type: Boolean,
      default: false,
    },
    isInCategoryList: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
    checkProductTags(array, tag = null) {
      if (array && array.length) {
        return array.filter((el) =>
          ['novo', 'najprodavanie', 'popust'].includes(el.name.toLowerCase())
        )
      }
    },
    addToCartProduct(item) {
      if (!item || !item.id) return false
      this.$cart.addItem(item.id)
      this.$toast.success('Proizvod dodan u košaricu!')
    },
  },
}
