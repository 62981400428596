
/* eslint-disable prefer-const */
import ControllerCategory from 'Controllers/ControllerCategory'
import ControllerProductList from 'Controllers/ControllerProductList'
import ControllerProduct from 'Controllers/ControllerProduct'
import ControllerLayeredNavigation from 'Controllers/ControllerLayeredNavigation'
import Product from '@/components/ecommerce/shop/product/product'
import clickOutside from 'v-click-outside'
import FourColSkeleton from '@/components/animations/skeletor-elements/skeleton-element-four-col'
import ProductPriceOnDemand from '@/components/modal/ProductPriceOnDemand'
import NumberInput from '../ui/inputs/numberInput'

export default {
  components: {
    ProductPriceOnDemand,
    ControllerCategory,
    ControllerProductList,
    ControllerProduct,
    ControllerLayeredNavigation,
    Product,
    FourColSkeleton,
    NumberInput,
  },
  directives: {
    clickOutside: clickOutside.directive,
  },
  data() {
    return {
      discountFilter: false,
      key: 0,
      nmbProd: 4,
      demandItem: {},
      priceOnDemandShow: false,
      clickOutsideConf: {
        handler: this.closeAll,
        middleware: this.clickOutsideMiddleware,
      },
      layeredRef: 'layeredNav',
      mobileLayered: false,
      dropdown: null,
      expand: [],
      layeredNavObject: {},
      filterExpand: false,
      priceDropdown: false,
      mobileMenu: false,
      miniCart: false,
      miniCartMobile: true,
      loading: true,
      localSavedPage: 1,
      localSavedItem: '',
      pageIsFullyLoaded: false,
      showFeaturedProducts: false,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 12,
        observer: true,
        observeParents: true,
        loop: false,
        breakpoints: {
          1400: {
            slidesPerView: 5,
            spaceBetween: 32,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 28,
          },
          850: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          640: {
            slidesPerView: 2.3,
            spaceBetween: 12,
          },
        },
      },
    }
  },
  computed: {
    numberOfProd() {
      return this.nmbProd
    },
  },
  watch: {
    $route: {
      // eslint-disable-next-line require-await
      async handler() {
        this.mobileMenu = false
        if (process.browser) {
          document.body.classList.remove('modal-html-open')
          document.body.classList.remove('modal-html-open')
        }
      },
    },
    mobileMenu: {
      handler(val) {
        if (val) {
          document.body.classList.add('modal-html-open')
        } else {
          document.body.classList.remove('modal-html-open')
        }
        this.closeAll()
        this.closeMiniCart()
      },
    },
    '$route.query.featured': {
      handler(v) {
        if (v) {
          setTimeout(() => {
            const activeSection = document.querySelector('#cms-content')
            const offset = activeSection.offsetTop - 144
            if (offset) {
              window.scrollTo({
                top: offset,
                behavior: 'smooth',
              })
            }
          }, 500)
        }
      },
    },
    '$route.params': {
      handler() {
        this.$set(this.$refs.controllerCategoryForProducts.options, 'page', 1)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.$wait(5)
    this.key++
    if (this.$refs && this.$refs.layeredNav && this.$refs.layeredNav.form) {
      this.$watch(
        '$refs.layeredNav.form',
        (newvalue, oldvalue) => {
          const keys = Object.keys(newvalue).map((key) => newvalue[key])
          if (keys.every((element) => element === null || element === false)) {
            this.expand = false
          }
        },
        { deep: true }
      )
    }
    this.$watch(
      () => {
        return (
          this.$refs.controllerCategoryForProducts &&
          this.$refs.controllerCategoryForProducts.products
        )
      },
      (val) => {
        if (val && val.length && val.length > 0) {
          this.nmbProd = val.length
        } else {
          this.nmbProd = 4
        }
      },
      {
        deep: true,
      }
    )
    await this.$wait(10)
    const { page } = this.$route?.query
    if (page && page > 0) {
      this.$set(this.$refs.controllerCategoryForProducts.options, 'page', +page)
    }
    const { slug } = this.$parent?.category
    if (slug && slug === 'akcija') {
      this.discountFilter = true
    }
  },
  methods: {
    async finishLoading() {
      if (!process.client) {
        return
      }
      await this.$wait(10)
      this.loading = false
    },
    setPage(optionsPage, page) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page },
      })
      setTimeout(() => {
        optionsPage.page = page
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 50)
    },
    setActiveItem(item) {
      this.demandItem = { ...item }
      this.priceOnDemandShow = true
    },
    checkIfProductIsInCijenaNaUpitCategory(categories = []) {
      if (!categories || !categories.length) return false
      return categories
        .map((el) => el.id)
        .some((el) => {
          return [1028, 1620].includes(el)
        })
    },
    closeAll() {
      this.expand.splice(0, this.expand.length)
    },
    clickOutsideMiddleware(e) {
      return true
    },
    closeMiniCartMobile() {
      this.miniCartMobile = false
    },
    openPriceDropdown() {
      this.priceDropdown = !this.priceDropdown
    },
    clearFilters() {
      this.$refs.layeredRef.resetFilters()
    },
    openMobileLayered() {
      this.mobileLayered = !this.mobileLayered
    },
    removeElFromLayeredNav(el) {
      const index = this.expand.indexOf(el)
      if (index > -1) {
        this.expand.splice(index, 1)
      } else {
        return false
      }
    },
    checkIfElementIsInArray(el) {
      const index = this.expand.indexOf(el)
      return index > -1
    },
  },
}
