
import LoginModal from '@/components/modal/LoginModal'
import clickOutside from 'v-click-outside'
import EditorLink from 'Editors/EditorLink'
import transitionExpand from '@/components/animations/transition-expand'
import RegisterModal from '@/components/modal/RegisterModal'
import { EventBus } from '@/plugins/eventBus'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LoginModal,
    // eslint-disable-next-line vue/no-unused-components
    RegisterModal,
    EditorLink,
    transitionExpand,
  },
  directives: {
    clickOutside: clickOutside.directive,
  },

  data() {
    return {
      smallPopup: false,
      clickOutsideConf: {
        handler: this.closeMiniCart,
        middleware: this.clickOutsideMiddleware,
      },
      miniCart: false,
      miniCartMobile: false,
      mobileMenu: false,
      mobileView: false,
      mobileList: null,
      mobile: false,
      activeItem: null,
      closeOnClick: false,
      showSearch: false,
      globalSearch: null,
      options: [
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
      ],
    }
  },
  watch: {
    '$route.path': {
      handler(v) {
        if (v === '/') {
          setTimeout(() => {
            document.body.id = 'hideAlert'
          }, 100)
          setTimeout(() => {
            document.body.id = ''
          }, 11000)
        }
        if (
          v &&
          !v.includes('shop') &&
          v &&
          !v.includes('product') &&
          v &&
          !v.includes('pretraga')
        ) {
          localStorage.setItem('categoryName', '')
          localStorage.setItem('categoryCurrentPage', '')
          localStorage.setItem('currentItemIn', '')
        }
        if ((v && v.includes('product')) || (v && v.includes('search'))) {
          let item = ''
          if (v.split('/product/').length && v.split('/product/')[1])
            item = v.split('/product/')[1]
          localStorage.setItem('currentItemIn', item)
        }
      },
    },
    $route: {
      handler() {
        this.mobileMenu = false
        if (process.browser) {
          document.body.classList.remove('modal-html-open')
          document.body.classList.remove('modal-html-open')
        }
      },
    },
    mobileMenu: {
      handler(val) {
        if (val) {
          document.body.classList.add('modal-html-open')
        } else {
          document.body.classList.remove('modal-html-open')
        }
        this.closeMiniCartMobile()
        this.closeMiniCart()
      },
    },
    '$route.query.s': {
      handler(v) {
        if (this.$refs.searchController) {
          if (!v || v.length === 0) {
            this.$set(this.$refs.searchController.form, 'search', '')
            return false
          }
          this.$set(this.$refs.searchController.form, 'search', v)
        }
      },
    },
  },
  mounted() {
    EventBus.$on('closeSmallModal', () => {
      this.smallPopup = false
    })
    if (this.$route.path === '/') {
      setTimeout(() => {
        document.body.id = 'hideAlert'
      }, 100)
      setTimeout(() => {
        document.body.id = ''
      }, 11000)
    }
  },
  methods: {
    clickOutsideMiddleware(e) {
      return true
    },
    changeStatementMiniCart() {
      this.miniCart = !this.miniCart
    },
    changeStatementMiniCartMobile() {
      this.miniCartMobile = !this.miniCartMobile
    },
    closeMiniCartMobile() {
      this.miniCartMobile = false
    },
    closeMiniCart() {
      this.miniCart = false
    },
    closeMobileMenu() {
      this.mobileMenu = false
    },
    openMobileMenu() {
      this.mobileMenu = !this.mobileMenu
    },
    changeStatement(type) {
      if (type === 'login') {
        this.$modal.show(
          LoginModal,
          {},
          {
            classes:
              'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important login-modal modal-custom',
            adaptive: true,
          }
        )
      } else {
        this.$modal.show(
          RegisterModal,
          {},
          {
            classes:
              'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important register-modal modal-custom',
            adaptive: true,
          }
        )
      }
    },
    createFocus() {
      this.showSearch = !this.showSearch
      if (this.showSearch && this.$refs.searchInput) {
        setTimeout(() => {
          this.$nextTick(() => this.$refs.searchInput.focus())
        }, 200)
      }
    },
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
    returnDefaultImage(images) {
      if (!images && !images.length) {
        return false
      }
      return images.filter((obj) => obj.default === true)
    },
  },
}
