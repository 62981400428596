import Vue from 'vue'

Vue.mixin({
  methods: {
    $formatDate(date) {
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    $wait(time) {
      return new Promise((res) => {
        setTimeout(() => {
          res()
        }, time)
      })
    },
    $shareUrl() {
      if (process.browser) {
        return window.location.href
      }
    },
    $currency(v) {
      const { label } = this.$application.currency
      return [Number(v).toFixed(2), label].join(' ')
    },
    $currencyNameSwitch(price) {
      if (!price) return
      if (price.includes('HRK')) {
        return price
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          .replace('HRK', 'Kn')
      } else {
        return price
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          .replace('EUR', '€')
      }
    },
    $returnEuroPrice(price) {
      return false
      // eslint-disable-next-line no-unreachable
      const conversionRate = 7.5345
      return this.$currencyNameSwitch(
        this.$currency(price * conversionRate)
      ).replace('€', 'Kn')
    },
  },
})
