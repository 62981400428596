import Vue from 'vue'
import inputText from '@/components/ui/inputs/textInput'
import selectInput from '@/components/ui/inputs/selectInput'
import checkboxInput from '@/components/ui/inputs/inputCheckbox'
import radioInput from '@/components/ui/inputs/inputRadio'

Vue.component('InputText', inputText)
Vue.component('InputSelect', selectInput)
Vue.component('InputRadio', radioInput)
Vue.component('InputCheckbox', checkboxInput)
