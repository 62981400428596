
export default {
  props: {
    productName: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showModal: {
      handler() {
        if (this.showModal) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = ''
        }
      },
      deep: true,
    },
    '$route.path': {
      handler() {
        if (process.browser) {
          document.body.style.overflow = ''
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeModal()
    }, 200000)
  },
  beforeDestroy() {
    if (process.browser) {
      document.body.style.overflow = ''
    }
  },
  methods: {
    closeModal() {
      if (this.$route.path === '/registracija') {
        this.$router.push('/prijava')
        return
      }
      if (this.$route.path === '/prijava') {
        this.$router.push('/')
        return
      }
      this.$router.push('/')
      this.$emit('closeModal', this.showModal)
    },
  },
}
