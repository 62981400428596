
import ControllerProductList from 'Controllers/ControllerProductList'

import Product from '@/components/ecommerce/shop/product/product'
export default {
  components: {
    ControllerProductList,
    Product,
  },
  data() {
    return {
      expand: false,
      layeredNavObject: {},
      filterExpand: false,
      swiperOptions: {
        slidesPerView: 1.5,
        spaceBetween: 12,
        observer: true,
        observeParents: true,
        loop: false,
        breakpoints: {
          1400: {
            slidesPerView: 5,
            spaceBetween: 32,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 28,
          },
          850: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
          640: {
            slidesPerView: 2.3,
            spaceBetween: 12,
          },
        },
      },
    }
  },
  async mounted() {
    if (this.$refs && this.$refs.layeredNav && this.$refs.layeredNav.form) {
      await this.$watch(
        '$refs.layeredNav.form',
        (newvalue, oldvalue) => {
          const keys = Object.keys(newvalue).map((key) => newvalue[key])
          if (keys.every((element) => element === null || element === false)) {
            this.expand = false
          }
        },
        { deep: true }
      )
    }
  },
  methods: {
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
  },
}
