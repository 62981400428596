
import ControllerResetPassword from 'Controllers/user/ControllerResetPassword'

export default {
  components: {
    ControllerResetPassword,
  },
  data() {
    return {
      changeTextPass: false,
      changeTextPassCheck: false,
    }
  },
}
