
import ControllerCart from 'Controllers/ControllerCart'
// import ControllerProduct from 'Controllers/ControllerProduct'
// import EditorProductList from 'Editors/EditorProductList'
export default {
  components: {
    // ControllerProduct,
    ControllerCart,
  },
  data() {
    return {
      couponCode: '',
      loading: false,
    }
  },
  methods: {
    async applyCouponCode(applyCoupon) {
      this.loading = true
      await applyCoupon()
      this.loading = false
    },
    errorCoupon() {
      this.loading = false
      this.$toast.error(
        'Unešeni kupon nije valjan, probajte sa drugim kuponom.'
      )
    },
    currencyNameSwitch(price) {
      if (!price) return
      return price.replace('HRK', 'Kn')
    },
    returnDefaultImage(images) {
      if (!images && !images.length) {
        return false
      }
      return images.filter((obj) => obj.default === true)
    },
  },
}
