
export default {
  props: {
    pendingStatus: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    showDesc: {
      type: Boolean,
      default: false,
    },
    isInCategoryList: {
      type: Boolean,
      default: false,
    },
    prod: {
      type: Object,
      default: null,
    },
  },
}
