import { render, staticRenderFns } from "./ProductPriceOnDemand.vue?vue&type=template&id=569f7ac2&scoped=true"
import script from "./ProductPriceOnDemand.vue?vue&type=script&lang=js"
export * from "./ProductPriceOnDemand.vue?vue&type=script&lang=js"
import style0 from "./ProductPriceOnDemand.vue?vue&type=style&index=0&id=569f7ac2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "569f7ac2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerCustomForm: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCustomForm.js').default})
