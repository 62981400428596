
/* eslint-disable prefer-const */
import _ from 'lodash'

export default {
  model: {
    prop: 'num',
    event: 'input',
  },
  props: {
    num: {
      type: [Number, String],
      default: 0,
    },
    id: {
      type: [String, Number],
      default: '',
    },
    classVal: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    fixedVal() {
      if (typeof this.num === 'string') {
        return (
          Math.round((parseInt(this.num) + Number.EPSILON) * 100) / 100
        ).toFixed(2)
      }
      return (Math.round((this.num + Number.EPSILON) * 100) / 100).toFixed(2)
    },
  },
  methods: {
    updateSelf: _.debounce(function (num) {
      if (num) {
        this.$emit(
          'input',
          (Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2)
        )
      }
    }, 1000),
  },
}
