
import ControllerCustomForm from 'Controllers/ControllerCustomForm'
import successNoticeModal from '@/components/modal/successNoticeModal'

export default {
  components: {
    ControllerCustomForm,
    successNoticeModal,
  },
  props: {
    productName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errored: false,
      loading: false,
      showSuccess: false,
      show: true,
    }
  },
  computed: {
    isMasterBranch() {
      return this.$application.api.ctx.$config.gitlabBranch === 'master'
    },
  },

  mounted() {
    if (this.$refs?.customForm) {
      this.$watch(
        () => {
          return this.$refs.customForm.form
        },
        () => {
          setTimeout(() => {
            if (!this.isMasterBranch) {
              this.$set(this.$refs.customForm.form, 'cLbmu', this.productName)
            } else {
              this.$set(this.$refs.customForm.form, 'gs0Qp', this.productName)
            }
          }, 300)
        },
        { deep: true }
      )
    }
  },
  methods: {
    checkForm(form, submit) {
      this.errored = false
      this.loading = true
      setTimeout(() => {
        if (this.isMasterBranch) {
          for (const [key, value] of Object.entries(form)) {
            if (!Array.isArray(value) && key !== 'abmcc') {
              if (form[key] === '' || form[key] === null) {
                this.errored = true
                this.$toast.error(
                  'Molimo popunite sva polja i ponovno pošaljite poruku.'
                )
              }
            }
          }
          if (!this.errored) submit()
          this.loading = false
        } else {
          for (const [key, value] of Object.entries(form)) {
            if (!Array.isArray(value) && key !== 'XjHjt') {
              if (form[key] === '' || form[key] === null) {
                this.errored = true
                this.$toast.error(
                  'Molimo popunite sva polja i ponovno pošaljite poruku.'
                )
              }
              if (!this.errored) submit()
              this.loading = false
            }
          }
        }
        this.errored = false
        this.loading = false
      }, 500)
    },
    successMessage() {
      this.showSuccess = true
      this.loading = false
    },
  },
}
