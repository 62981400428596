
import Product from '@/components/ecommerce/shop/product/product'

export default {
  components: {
    Product,
  },
  data() {
    return {
      loading: true,
      searchValue: '',
    }
  },
  beforeMount() {
    this.loading = true
  },
  mounted() {
    this.$set(this.$refs.searchController.options, 'page', 1)
  },
  methods: {
    setPage(optionsPage, page) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page },
      })
      setTimeout(() => {
        optionsPage.page = page
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 50)
    },
    submit() {
      const query = {
        ...this.$route.query,
        search: encodeURIComponent(this.searchValue),
      }
      this.$router.replace({ query })
    },
  },
}
