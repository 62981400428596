// Types
import mixins from"vue-typed-mixins";// Mixins
import ControllerBase from"../../../mixins/ControllerBase";import{WithPartialInject}from"../../../utils/withInject";import Consentable from"../../../mixins/Consentable";const checkoutInject=WithPartialInject("delivery","billing","stepState","goToPreviousStep","companyState");// Utils
import consola from"consola";// Enums
import{AddressType,CheckoutSteps}from"../../../enums/Checkout";import{FormWithConsent}from"../../../enums/Gdpr";const ControllerPayment=mixins(ControllerBase,Consentable(FormWithConsent.Checkout),checkoutInject).extend({name:"ControllerPayment",data(){var a;return{paymentMethods:[],form:{methodId:(null===(a=this.$cart.storage.cart)||void 0===a?void 0:a.paymentMethodId)||0},lastSavedMethod:0,orderStatus:!0}},// TODO: CELERO CUSTOMIZATION 1. For now, there is no backend for me to retrieve the PayPal planConfig for CeleroOne PayPal subscriptions, so i had to hardcode PayPal plan configuration if user selected Germany as his country and if prop asCelero is true. This prop is obviously not documented, and once backend for planConfigs is done, remove this.
props:{asCelero:{type:Boolean,default:!1},skipShippingStep:{type:Boolean,default:!1}},computed:{isStepActive(){return this.stepState.activeStep===CheckoutSteps.Payment},user(){return this.$auth.user||this.$cart.guestUser},// If user saves his addresses, goes to next step and then closes checkout, his billing and delivery addresses will be saved, but companyOIB and companyName cannot be saved, they have to be set explicity every time user enters checkout page.
// We have to stop saving payment method inside created() if as-company prop was used inside controller-address but companyName and companyOIB were not set
companyDataValid(){var a;if(!(null!==(a=this.companyState)&&void 0!==a&&a.isCompany))return!0;// If useDifferentBillingAddress === false inside ControllerAddress, billingAddress was previously set to shipping address anyways, so we can safely destruct only from billing address
const{companyName:b,companyOIB:c}=this.billing;return!!b&&!!c}},watch:{// 'form.methodId': 'savePaymentMethod',
// '$cart.storage.cart.deliveryMethodId': 'getApplicablePaymentMethods',
"user.id":"getApplicablePaymentMethods",isStepActive:{async handler(a){a&&(await this.getApplicablePaymentMethods())}},consentsFetched:{async handler(a){a&&(await this.getApplicablePaymentMethods())}}},methods:{async getApplicablePaymentMethods(){var a;if(!this.consentsFetched||!this.isStepActive)return;// TODO: CELERO CUSTOMIZATION 2. This condition for reseting lastSavedMethod was also done explicitly for Celero. Dont forget to remove this condition when plan configs are done on backend. Without this, if user submits his address data, he goes to payment and inside savePaymentMethod submit will be called immediately, order will be created and PayPal config will be set. The problem occurs if user goes back to addresses step, changes country and then goes to payment. His old paypal config will be used which is bad since i have to send planConfig if selected country is Germany.
if(this.asCelero&&(this.lastSavedMethod=0),!this.$cart.session)return void consola.error("Cart session not initialised!");if(!this.$cart.isEProductCart&&!(null!==(a=this.$cart.storage.cart)&&void 0!==a&&a.deliveryMethodId))return;const{user:b}=this;if(!b||!b.id||!b.addresses)return;const c=a=>b.addresses.find(b=>b.type===a),d=c(AddressType.Main),e=c(AddressType.Shipping);if(!(null!==d&&void 0!==d&&d.id)||!(null!==e&&void 0!==e&&e.id))return;const f={userId:b.id,cartId:this.$cart.session.id,protectCode:this.$cart.session.protectCode,userBillingAddressId:d.id},{payment:g}=this.$api.ecommerce.checkout,h=await g.getApplicablePaymentMethods(f),i=this.paymentMethods;if(h.errored)return void i.splice(0);i.splice(0,i.length,...h.data);const j=this.checkIfStepShouldBeSkipped();j&&(this.form.methodId=this.paymentMethods[0].id,await this.savePaymentMethod(this.form.methodId,this.lastSavedMethod))},checkIfStepShouldBeSkipped(){//Do not always instantly call savePaymentMethod because companyName and companyOIB have to be set explicitly each time user enters checkout page
return!!this.companyDataValid&&!(1!==this.paymentMethods.length)&&"HPF"===this.paymentMethods[0].paymentType},async savePaymentMethod(a=0,b=0){var c;if(a!==this.lastSavedMethod){if(!this.$cart.session)return void consola.error("Cart session not initialised!");const d=null===(c=this.user)||void 0===c?void 0:c.id;if(d){const c={userId:d,cartId:this.$cart.session.id,protectCode:this.$cart.session.protectCode,paymentMethodId:a};if(this.companyDataValid){const{companyName:a,companyOIB:b}=this.billing;// @ts-ignore
c.companyData={companyName:a,oib:b}}const e=await this.callWithStatusUpdate(this.$api.ecommerce.checkout.payment.savePaymentMethod(c));if(e.errored)return void(this.form.methodId=b);this.lastSavedMethod=a;const f=this.checkIfStepShouldBeSkipped();f&&(await this.submit())}}},async submit(){// console.log(this.render())
if(!this.status.pending&&this.orderStatus){var a,b;this.orderStatus=!1;const c=await this.validateConsents(null===(a=this.user)||void 0===a?void 0:a.email);if(!c)return void(this.orderStatus=!0);if(!this.form.methodId)return this.$toast.error(this.$t("error.paymentMethodUndefined")),void(this.orderStatus=!0);if(await this.savePaymentMethod(this.form.methodId,this.lastSavedMethod),!this.$cart.session)return this.orderStatus=!0,void consola.error("Cart session not initialised!");const d=null===(b=this.user)||void 0===b?void 0:b.id;if(!d)return void(this.orderStatus=!0);const{origin:e}=window.location,f=e+this.localePath("/checkout/failure"),g=e+this.localePath(this.$Page.CheckoutSuccess),h=await this.$api.ecommerce.checkout.order.create({userId:d,cartId:this.$cart.session.id,protectCode:this.$cart.session.protectCode,// @ts-ignore
giftMessage:this.form.giftMessage,// @ts-ignore
orderMessage:this.form.orderMessage,// @ts-ignore
to:this.form.to,// @ts-ignore
from:this.form.from,failureURL:f,successURL:g});if(h.errored)return void(this.orderStatus=!0);const i=this.paymentMethods.find(a=>a.id===this.form.methodId),{html:j}=h.data;/*
        @Ivan Gašparović
        Imamo 3 vrste metoda:
          HPP -> Saznas kroz methodCode abriviacija znaci "Hosted payment page" znaci ide redirect na payment metodu i oni onda redirectaju na success
          HPF  -> isto methodCode abriviacija je "Hosted payment form" znaci mi hostamo formu njihovu na nasoj stranici i mi redirectamo na success
          Bez online paymenta -> kupac placa gotovinom -> direktno ide na success page
         */if("string"!=typeof j)this.handlePaypal(j);else if(!(null!==i&&void 0!==i&&i.paymentType))this.$emit("submitted"),this.$cart.clearSession(),this.$router.push({path:this.localePath(this.$Page.CheckoutSuccess),query:{orderSlug:h.data.orderSlug}});else{const a=document.createElement("div");a.innerHTML=j;const b=a.querySelector("form");if(!b)return;document.body.appendChild(b),b.submit()}}},handlePaypal(a){const b=null!==a&&void 0!==a&&a.isSubscription?{// Set up the transaction
createSubscription:(b,c)=>{const{plan_id:d,quantity:e}=a,f={plan_id:d};e&&(f.quantity=e);return this.asCelero&&"DE"!==this.billing.country&&(f.plan={taxes:{percentage:"0",inclusive:!1}}),c.subscription.create(f)},// Finalize the transaction
onApprove:async b=>{const c=await this.$api.ecommerce.checkout.order.pgSuccess(a.orderId,{details:b,internalRedirect:!0});if(c.errored)return;this.$emit("submitted"),this.$cart.clearSession();const{url:d}=c.data,e=d.replace(/https*:\/\//,""),f="/"+e.split("/").splice(1).join("/");this.$router.push(f)}}:{// Set up the transaction
createOrder:(b,c)=>c.order.create({purchase_units:[{amount:{value:a.total}}]}),// Finalize the transaction
onApprove:(b,c)=>c.order.capture().then(async b=>{const c=await this.$api.ecommerce.checkout.order.pgSuccess(a.orderId,{details:b,internalRedirect:!0});if(c.errored)return;this.$emit("submitted"),this.$cart.clearSession();const{url:d}=c.data,e=d.replace(/https*:\/\//,""),f="/"+e.split("/").splice(1).join("/");this.$router.push(f)})},c=document.createElement("script");document.body.appendChild(c),c.onload=()=>{// Render the PayPal button into #paypal-button-container
// @ts-ignore
const a=paypal;a.Buttons(b).render("#paypal-button-container")},c.src=null===a||void 0===a?void 0:a.scriptUrl}},render(){const{paymentMethods:a,form:b,status:c,submit:d,isStepActive:e,goToPreviousStep:f,assignedConsents:g}=this;// @ts-ignore
return this.renderContainer({paymentMethods:a,form:b,status:c,submit:d,isStepActive:e,goToPreviousStep:()=>f(this.$props.skipShippingStep),// Pass the prop as an argument
consents:g})}});export default ControllerPayment;