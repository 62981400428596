
export default {
  name: 'SkeletonElementFourCol',
  props: {
    numberOfProducts: {
      type: Number,
      default: 4,
    },
  },
}
