import { render, staticRenderFns } from "./LoginView.vue?vue&type=template&id=40977e90&scoped=true&class=popup-wrapper"
import script from "./LoginView.vue?vue&type=script&lang=js"
export * from "./LoginView.vue?vue&type=script&lang=js"
import style0 from "./LoginView.vue?vue&type=style&index=0&id=40977e90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40977e90",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerLogin: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/user/ControllerLogin.js').default})
