
export default {
  computed: {
    cartObject() {
      return this.$store.state.cart
    },
    // returnName() {
    //   if (
    //     this.$auth &&
    //     this.$auth.user &&
    //     this.$auth.user.details &&
    //     this.$auth.user.details.displayName
    //   ) {
    //     return `${this.$auth.user.details.displayName}, zahvaljujemo na vašoj narudžbi`
    //   }
    //   return 'Zahvaljujemo na vašoj narudžbi'
    // },
  },
  mounted() {
    this.sendData()
  },
  methods: {
    sendData() {
      let items = null
      items = this.cartObject.items.map((el) => {
        return {
          item_name: el.productData.name,
          item_id: el.productId,
          quantity: el.qty,
          item_category: el.productData.categories[0],
          price: el.prices.base.finalPrice,
        }
      })
      if (this.cartObject && items) {
        window.dataLayer?.push({
          event: 'purchase',
          ecommerce: {
            transaction_id: `${this.$route.query.orderSlug}`,
            value: `${this.cartObject?.prices?.converted?.orderTotal}`,
            tax: `${this.cartObject?.prices?.converted?.tax?.orderTotal}`,
            currency: 'EUR',
            coupon: this.cartObject.couponCode
              ? this.cartObject.couponCode
              : 'none',
            items,
          },
        })
        // this.$gtm.push({
        //   event: 'purchase',
        //   transaction_id: this.$route.query.orderSlug,
        //   value: this.cartObject.prices.base.cartTotal,
        //   currency: 'HRK',
        //   aw_feed_country: 'HR',
        //   aw_feed_language: 'HR',
        //   items,
        // })
      }
    },
  },
  head() {
    return {
      script: [
        {
          hid: 'gtm',
          innerHTML: `window.dataLayer = window.dataLayer || [];`,
          type: 'text/javascript',
        },
      ],
    }
  },
}
