
import ControllerPost from 'Controllers/ControllerPost'
import ControllerNewsletter from 'Controllers/ControllerNewsletter'

export default {
  components: {
    ControllerPost,
    ControllerNewsletter,
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    returnRoute() {
      if (process.browser) {
        return window.location
      }
    },
  },
  methods: {
    callback() {
      this.$toast.success('Vaš e-mail je uspješno poslan. Hvala vam!')
    },
  },
}
