
/* eslint-disable no-useless-return */

import ControllerLogin from 'Controllers/user/ControllerLogin'
import RegisterModal from '@/components/modal/RegisterModal'
import ForgotPasswordModal from '@/components/modal/ForgotPasswordModal'
// import successNoticeModal from '../modal/successNoticeModal'
export default {
  components: {
    ControllerLogin,
    // eslint-disable-next-line vue/no-unused-components
    RegisterModal,
    // eslint-disable-next-line vue/no-unused-components
    ForgotPasswordModal,
  },
  data() {
    return {
      notice: false,
      type: false,
      // notice: false,
      changeTextPass: false,
      redirectToCartAfterLogin: false,
    }
  },
  created() {
    const { abandonedCart } = this.$route.query
    if (abandonedCart) this.redirectToCartAfterLogin = true
  },
  beforeDestroy() {
    this.notice = false
  },
  methods: {
    textPassword() {
      this.changeTextPass = !this.changeTextPass
    },
    callback() {
      // this.notice = true
      if (this.redirectToCartAfterLogin) {
        setTimeout(() => {
          this.$router.push({ path: 'kosarica' })
        }, 200)
        return false
      } else if (
        this.$router.path === '/prijava' &&
        !this.redirectToCartAfterLogin
      ) {
        this.$router.push('/')
      }
      this.$emit('close')
    },
    changeStatement() {
      this.$modal.show(
        RegisterModal,
        {},
        {
          classes:
            'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important register-modal modal-custom',
          adaptive: true,
        }
      )
      this.$emit('close')
    },
    openModalForgotPass() {
      this.$modal.show(
        ForgotPasswordModal,
        {},
        {
          classes:
            'md:max-w-630w !important xss:w-87pc w-90pc !important my-0 mx-auto left-0 !important forgot-password-modal modal-custom',
          adaptive: true,
        }
      )
      this.$emit('close')
    },
  },
}
